import React from 'react';
import './App.css';
import logo from './bridgit-beta-logo.png'; // Importing the logo
import video from './90-sec-bridgit-demo.mp4'

const App = () => {
  return (
    <div className="landing-page">
      <header className="header">
        <img src={logo} alt="Bridgit Logo" className="logo" /> {/* Using the logo */}
      </header>
      <main className="content">
        <section className="intro">
          <h1 className="intro-headline">CRM integrations for charities, fast</h1>
          <p>Bridgit automates your data admin so you can focus on building relationships. Sound good?</p>
          <a href="mailto:hello@hibridigit.com" className="btn">Request Access</a>
        </section>
        <section className="details">
          <div className="text">
            <h2>Repetitive data tasks? Leave them to Bridgit</h2>
            <p>
              Charity staff are spending far too much time on manual data admin. That’s why we built Bridgit: the integration hub for charities.
            </p>
            <p>
              Find out how automating data movement between platforms helps fundraisers, supporter care teams, and data managers in this demo video.
            </p>
            <a href="mailto:hello@hibridigit.com" className="btn">Request Access</a>
            <a href="#" className="btn secondary">Book a Demo</a>
          </div>
          <div className="video">
            <video controls className="embedded-video">
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="contact-info">
          <a href="mailto:hello@hibridigit.com">hello@hibridigit.com</a> |
          <a href="https://www.linkedin.com/company/hibridgit" target="_blank" rel="noopener noreferrer">LinkedIn</a> |
          0208 064 1259 | 97 Charlotte Street, London, England, W1T4QA
        </div>
        <div className="company-info small-text">
          <br></br>
          Bridgit Technologies Ltd is a company registered in England and Wales under registration number 11639162.
        </div>
      </footer>
    </div>
  );
}

export default App;
